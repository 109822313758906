<template>
  <div>
    <p
      class="clearfix mb-0"
      :class="{
        'd-none': $store.state.studentNotes.isExpanded
      }"
    >
      <span class="float-md-left d-block d-md-inline-block mt-25 footer-text">
        {{ $t('COPYRIGHT') }}  © {{ new Date().getFullYear() }}
        <b-link
          class="ml-25"
          href="#"
          target="_blank"
        >GEC</b-link>
        <span class="d-none d-sm-inline-block ">, {{ $t('all-rights-reserved') }}</span>
      </span>
    </p>
    <help-video-list
      v-if="(isASchool || isATeacher || isAStudent) && !hideHelpVideoStatus"
      :user-type="self.usertype"
    />
  </div>

</template>

<script>
import { BLink } from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import {
  USER_TYPE_SCHOOL,
  USER_TYPE_TEACHER,
  USER_TYPE_STUDENT,
} from '@/const/userType'
import { mapGetters } from 'vuex'
import HelpVideoList from '../../../views/common/components/HelpVideoList.vue'

export default {
  components: {
    BLink,
    HelpVideoList,
  },
  data() {
    return {
      self: getUserData(),
      // hideHelpVidep: '0',
    }
  },
  computed: {
    ...mapGetters('appConfig', ['getSchoolSettings', 'getDistrictSettings']),
    isASchool() {
      return this.self.usertype === USER_TYPE_SCHOOL
    },
    isATeacher() {
      return this.self.usertype === USER_TYPE_TEACHER
    },
    isAStudent() {
      return this.self.usertype === USER_TYPE_STUDENT
    },
    // currentRoute() {
    //   return this.$route.name
    // },

    hideHelpVideoStatus() {
      // check district level setting
      const distHelpVideo = this.getDistrictSettings?.find(metaValue => metaValue.key === 'help_video')?.value ?? '0'
      // check school level setting
      const schoolHelpVideo = this.getSchoolSettings?.find(metaValue => metaValue.key === 'help_video')?.value ?? '0'

      let helpVideoValue = false
      if (distHelpVideo === '1' || schoolHelpVideo === '1') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        helpVideoValue = true
      }
      return helpVideoValue
    },
  },
  watch: {
    // currentRoute(newVal) {
    //   const routeCheckForStudentLab = [
    //     'teacher-view-students', 'teacher-lessons', 'teacher-lesson', 'teacher-problems',
    //     'teacher-class-calendar', 'teacher-class-test', 'student-essay', 'teacher-class',
    //     'teacher-monitor-class', 'teacher-higher-level-report',
    //     'student-view-students', 'student-lessons', 'student-lesson', 'student-problems',
    //     'student-class',
    //   ]
    //   if (routeCheckForStudentLab.includes(newVal)) {
    //     const schoolData = JSON.parse(localStorage.getItem('schoolData'))
    //     this.hideHelpVidep = schoolData.help_video || '0'
    //   } else {
    //     this.hideHelpVidep = '0'
    //   }
    // },
  },
}
</script>
