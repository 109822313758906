<template>
  <div>
    <b-card
      v-if="showHelpvideoList"
      class="help-video-list-modal p-1 mb-0"
      no-body
    >
      <h4>{{ $t('help-videos-moduel.help-videos-list') }}</h4>
      <b-overlay
        :show="isProcessing"
      >
        <template v-if="isASchool || isATeacher">
          <v-select
            id="video-language"
            v-model="language"
            name="Language"
            :options="languageList"
            :reduce="(item) => item.value"
            :placeholder="$t('help-videos-moduel.select-language')"
            @input="getVideoByLanguage"
          />
        </template>
        <ul
          v-if="videoList.length > 0"
          class="mt-1 pl-0"
        >
          <li v-for="(video, index) in videoList"
              :key="index"
          >
            <a @click.prevent="showHelpvideoModal(video)">
              <feather-icon
                icon="PlayIcon"
                size="12"
                style="margin-top:5px"
              />
              <span class="ml-1">{{ video.title }}</span>
            </a>
          </li>
        </ul>
        <b-alert
          v-else
          show
          variant="warning"
          class="p-1"
        >
          <span>{{ $t('messages.no-videos-available') }}</span>
        </b-alert>
      </b-overlay>
    </b-card>

    <!--Button-->
    <div class="w-100">
      <b-button
        v-b-tooltip.bottom.hover
        variant="success"
        class="help-video-btn"
        :title="$t('help-videos-moduel.toolttip-title')"
        :class="{ active: showHelpvideoList }"
        @click="showHelpvideoList = !showHelpvideoList"
      >
        <feather-icon
          :icon="showHelpvideoList ? 'XIcon' : 'VideoIcon'"
          size="24"
        />
      </b-button>
    </div>
    <!--Button-->

    <b-modal
      id="modal-help-video"
      ref="modal-help-video"
      ok-only
      size="lg"
      ok-variant="primary"
      :ok-title="$t('confirm')"
      :click-to-close="false"
      :no-close-on-backdrop="true"
      modal-class="modal-primary"
      centered
      hide-footer
      :title="`${selectedVideo.title}`"
      no-close-on-esc
    >
      <iframe
        width="100%"
        height="450"
        :src="embededUrl"
        frameborder="0"
        allowfullscreen
      />
      <p v-if="selectedVideo.description"
         class="p-1"
      >
        {{ $t('help-videos-moduel.description') }} : {{ selectedVideo.description }}
      </p>
      <div class="modal-footer">
        <label>
          {{ $t('help-videos-moduel.dont-show-again') }}
          <input v-model="viewLaterValue"
                 type="checkbox"
                 @change="viewLater"
          >
        </label>
      </div>
    </b-modal>
  </div>
</template>

<script>
import useJwt from '@/auth/jwt/useJwt'
import {
  USER_TYPE_SCHOOL,
  USER_TYPE_TEACHER,
} from '@/const/userType'
import { isVisible } from '@/utils/visibilitySettings'
import {
  BButton, VBTooltip, BCard, BOverlay, BModal, BAlert,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'HelpVideoList',
  components: {
    BButton,
    BCard,
    BOverlay,
    BModal,
    BAlert,
    vSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    userType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showHelpvideoList: false,
      videoList: [],
      selectedVideo: {},
      embededUrl: null,
      isProcessing: false,
      languageList: [
        { value: 'English', label: 'English' },
        { value: 'Spanish', label: 'Español' },
        { value: 'Korean', label: '한국어' },
        { value: 'Japanese', label: '日本語' },
        { value: 'Vietnamese', label: 'Tiếng Việt' },
        { value: 'Hindi', label: 'हिन्दी, हिंदी' },
      ],
      language: null,
      viewLaterValue: false,
    }
  },
  computed: {
    isASchool() {
      return this.userType === USER_TYPE_SCHOOL
    },
    isATeacher() {
      return this.userType === USER_TYPE_TEACHER
    },
    popupHelpVideo() {
      const schoolSettings = this.$store.state.appConfig?.schoolInfo?.user_meta
      const districtSettings = this.$store.state.appConfig?.districtInfo?.user_meta

      if (!schoolSettings && !districtSettings) return false

      return isVisible('help_video_pop_up', schoolSettings, districtSettings)
    },
  },
  watch: {
    popupHelpVideo(val) {
      if (val) {
        if (!this.viewLaterValue && this.popupHelpVideo && this.videoList.length) { this.showHelpvideoModal(this.videoList[0]) }
      }
    },
  },
  created() {
    this.getHelpVideosList()
  },
  methods: {
    getHelpVideosList() {
      this.isProcessing = true
      useJwt.getAllRelatedHelpVideos()
        .then(res => {
          this.videoList = res.data.data
          this.viewLaterValue = res.data.viewLater
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    showHelpvideoModal(video) {
      this.$refs['modal-help-video'].show()
      this.selectedVideo = video
      if (this.selectedVideo.link.includes('youtube.com/watch')) {
        const videoId = this.selectedVideo.link.split('v=')[1]
        this.embededUrl = `https://www.youtube.com/embed/${videoId}?rel=0&modestbranding=1`
      } else {
        this.embededUrl = this.selectedVideo.link
      }
    },
    getVideoByLanguage() {
      this.isProcessing = true
      useJwt.getAllRelatedHelpVideos({
        params: {
          language: this.language,
        },
      })
        .then(res => {
          this.videoList = res.data?.data
        })
        .catch(error => {
          console.log(error)
        }).finally(() => {
          this.isProcessing = false
        })
    },
    viewLater() {
      useJwt.storeUserMeta('viewLater', this.viewLaterValue).then(res => {
        this.showSuccessMessage(res.data.message)
      }).catch(error => {
        console.log(error)
      })
    },
  },
}

</script>

<style lang="scss" scoped>
.help-video-btn {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    padding: 0 12px;
    bottom: 30px;
    z-index: 10000;
    position: fixed;
    border: 2px solid white !important;
}

.help-video-btn.btn-success {
    right: 2%;
    top: 35%;
}

.help-video-list-modal {
    position: fixed;
    bottom: 170px;
    right: 6%;
    z-index: 1000;
    width: 300px;
    height: 300px;
    overflow: scroll;
}

.help-video-list-modal ul li {
    margin-bottom: 4px;
}
.help-video-list-modal ul li a{
    display: flex;
    font-size: 16px;
}
.help-video-list-modal ul li a:hover span{
    text-decoration: underline;
}
// .active {
//     transform: translateY(-16px);
// }

</style>
